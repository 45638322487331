export const ROL = {
  SOPORTE: 1,
  ADMIN: 2,
  CANDIDATO: 3,
  COORDINADOR_GENERAL: 4,
  COORDINADOR_FEDERAL: 5,
  COORDINADOR_LOCAL: 6,
  PROMOTOR: 7,
  CALLCENTER: 8,
  REPORTES: 9,
};

export const permisos = {
  Comite: null,
  Buscador: null,
  Avances: null,
  AvanceComite: null,
  AvanceConvencidos: null,
  AvanceRedes: null,
  AvanceDefensaVoto: null,
  Reporte: null,
  ReporteVisitaDia: null,
  ReporteSimpatizantes: null,
  Simpatizantes: null,
  Usuario: null,
  Administracion: null,
  AdministracionDistritosFederales: null,
  AdministracionDistritosLocales: null,
  AdministracionSecciones: null,
  AdministracionCambioINE: null,
  Noticias: null,
  Grupos: null,
  Materiales: null,
  Validacion: null,
  Ayuda: null,
  ErroresVisita: null,
};

export const generarComentariosValidacion = () => [
  {
    label: "SELECCIONAR OPCIÓN",
    value: null,
    type: "ninguna",
  },
  {
    label: "PERSONA CONVENCIDA",
    value: 1,
    type: "aprobado",
  },
  {
    label: "TELÉFONO NO DISPONIBLE (OCUPADO, BUZÓN, FUERA DE SERVICIO)",
    value: 2,
    type: "seguimiento",
  },
  {
    label: "PERSONA NO DISPONIBLE",
    value: 3,
    type: "seguimiento",
  },
  {
    label: "NO CONVENCIDA CON EL PROYECTO",
    value: 4,
    type: "rechazado",
  },
  {
    label: "NO SE LE INFORMÓ SOBRE EL PROYECTO",
    value: 5,
    type: "rechazado",
  },
  {
    label: "DESCONOCE TOTALMENTE EL TEMA",
    value: 6,
    type: "rechazado",
  },
  {
    label: "EL NÚMERO NO EXISTE",
    value: 7,
    type: "rechazado",
  },
  {
    label: "NÚMERO TELEFÓNICO INVÁLIDO O VACÍO",
    value: 8,
    type: "rechazado",
  },
  {
    label: "RESPONDIÓ EL PROMOTOR",
    value: 9,
    type: "rechazado",
  },
  {
    label: "EL NÚMERO NO PERTENECE AL SIMPATIZANTE",
    value: 10,
    type: "rechazado",
  },
  {
    label: "EL SIMPATIZANTE COLGÓ LA LLAMADA",
    value: 11,
    type: "rechazado",
  },
  {
    label: "VISITA DUPLICADA",
    value: 12,
    type: "rechazado",
  },
  {
    label: "NINGUNA DE LAS ANTERIORES",
    value: -1,
    type: "ninguna",
  },
];
