import React from 'react';
import Paperbase from "./paperbase";

export default (props) => {

  return (
    <React.Fragment>
      <Paperbase title={props.title} filter={props.filter}>
        {props.children}
      </Paperbase>
    </React.Fragment>
  );
}