import React, { useState, useEffect, useContext } from "react";
import { Grid, CircularProgress, Box } from "@material-ui/core";
import SelectWithSearch from "react-select";
import PropTypes from "prop-types";
import { capitalize } from "../../utils";
import { useApp } from "../../context/appContext";
import perfilContext from "../../context/perfil/perfilContext";
import { ROL } from "../../constants";
import zIndex from "@material-ui/core/styles/zIndex";

const isValidModel = (model) =>
  model === "distrito" || model === "seccion" || model === "promotor";

const SireSelector = (props) => {
  const {
    model,
    onSelect,
    allowAll,
    detail,
    isLoading,
    isDisabled,
    defaultValue,
    isMulti,
    selectedList,
    federalOnly,
  } = props;
  const [mainList, setMainList] = useState([]);
  const { perfil } = useContext(perfilContext);
  const {
    setDistrito,
    distritos,
    setSeccion,
    secciones,
    getSecciones,
    setPromotor,
    promotores,
    getPromotores,
  } = useApp();

  useEffect(() => {
    if (!isValidModel(model)) throw "SireSelector: error: modelo inválido.";
    let final = [];
    if (allowAll) final.push({ label: `TODOS`, value: -1 });
    if (model === "distrito") {
      let dtos = distritos.map(mapDistritosOptions);
      if (federalOnly) dtos = dtos.filter((e) => e.tipo === 1);
      setMainList([...final, ...dtos]);
    } else if (model === "seccion") {
      setMainList([...final, ...secciones.map(mapSeccionesOptions)]);
    } else if (model === "promotor") {
      setMainList([...final, ...promotores.map(mapPromotoresOptions)]);
    }
  }, [distritos, secciones, promotores, federalOnly]);

  const mapDistritosOptions = (dto) => {
    if (
      (!federalOnly && detail && perfil.rol <= ROL.COORDINADOR_FEDERAL) ||
      perfil.rol === ROL.REPORTES ||
      perfil.rol === ROL.CALLCENTER
    ) {
      let group = {
        label: `Distrito Federal ${dto.nombre}`,
        options: dto.distritos.map((dst) => {
          return {
            label: `Distrito Local ${dst.nombre}`,
            value: dst.id,
          };
        }),
      };
      return group;
    }
    return {
      value: dto.id,
      label: `Distrito ${dto.tipo === 1 ? "Federal" : "Local"} ${dto.nombre}`,
      tipo: dto.tipo,
    };
  };

  const mapSeccionesOptions = (dto) => {
    return { value: dto.id, label: dto.nombre };
  };

  const mapPromotoresOptions = (dto) => {
    return { value: dto.uid, label: dto.nombre };
  };

  function onSelectChange(event) {
    if (model === "distrito") {
      setDistrito(event);
      setSeccion(null);
      setPromotor(null);
      getSecciones({ distrito: event.value });
      getPromotores({ distrito: event.value });
    }
    if (model === "seccion") {
      setSeccion(event);
      setPromotor(null);
      getPromotores({ seccion: event.value });
    }
    if (model === "promotor") setPromotor(event);
    if (onSelect) onSelect(event);
  }

  function onMultiSelectChange(event) {
    // setSelectedList(event); // Salía error en producción
    if (model === "distrito") {
      let distritos = event.map((e) => e.value).join(",");
      setDistrito(event);
      setSeccion(null);
      setPromotor(null);
      if (distritos.includes("-1")) {
        getSecciones();
        getPromotores();
      }
      getSecciones({ distrito: distritos });
      getPromotores({ distrito: distritos });
    } else if (model === "seccion") {
      setSeccion(event);
      setPromotor(null);
      getPromotores({ seccion: event.value });
    } else if (model === "promotor") {
      setPromotor(event);
    }
    if (onSelect) onSelect(event);
  }

  const customStyles = {
    maxHeight: 400,
    overflow: 'auto',
    width: '100%',
    ///.....
  }

  const reactSelectStyles = base => ({ ...base, zIndex: 999 })

  return (
    <Box display="flex" alignItems="center" justifyItems="center">
      <Box flexGrow={1}>
        <SelectWithSearch
          {...props}
          options={mainList}
          label={capitalize(model)}
          styles={reactSelectStyles}
          onChange={(event) =>
            isMulti ? onMultiSelectChange(event) : onSelectChange(event)
          }
          isDisabled={isLoading || isDisabled}
          value={isMulti ? selectedList : defaultValue}
        />
      </Box>
      {isLoading && <CircularProgress style={{ marginLeft: 10 }} size={25} />}
    </Box>
  );
};

SireSelector.propTypes = {
  model: PropTypes.string.isRequired,
  onSelect: PropTypes.func,
  allowAll: PropTypes.bool, // habilitar funcion de 'todos'
  detail: PropTypes.bool, // mostrar selector detallado
  isLoading: PropTypes.bool,
  isDisabled: PropTypes.bool,
  onlySub: PropTypes.bool,
  isMulti: PropTypes.bool,
  federalOnly: PropTypes.bool,
};

export default SireSelector;
