import asyncComponent from "../components/AsyncComponent";

const PedidoView = asyncComponent(() => import("./dashboard/detail"));
const Permissions = asyncComponent(() => import("./auth/permissions"));
const Buscador = asyncComponent(() => import("./buscador/index"));
const Dasboard = asyncComponent(() => import("./dashboard/index"));
const Dasboardr = asyncComponent(() => import("./dashboard/indexReal"));
const DasboardDistrito = asyncComponent(() => import("./dashboard/distrito"));
const DasboardSeccion = asyncComponent(() => import("./dashboard/seccion"));
const DashboardDetail = asyncComponent(() => import("./dashboard/detail"));
const DashboardConsolidado = asyncComponent(() =>
  import("./dashboard/consolidado")
);

const Home = asyncComponent(() => import("./home"));
const NewHome = asyncComponent(() => import("./newHome"));
const NewVisit = asyncComponent(() => import("./nuevaVisita"));
const GenerarReporte = asyncComponent(() =>
  import("./reportes/generarVisitas")
);
const ImportarVisita = asyncComponent(() => import("./visita/importar"));

const SinTerminar = asyncComponent(() => import("./dev/sinTerminar"));
const GenerarReporteComite = asyncComponent(() => import("./reportes/generar"));
const MonitoreoCallcenter = asyncComponent(() =>
  import("./reportes/callcenter")
);
const ReportesCallcenter = asyncComponent(() =>
  import("./reportes/callcenterReporte")
);

const DashboardComiteIndex = asyncComponent(() =>
  import("./dashboard/comiteIndex")
);
const DashboardComiteLocal = asyncComponent(() =>
  import("./dashboard/comiteLocal")
);
const DashboardComiteSeccion = asyncComponent(() =>
  import("./dashboard/comiteSeccion")
);

const DashboardComitesAgrupados = asyncComponent(() =>
  import("./dashboard/comitesAgrupados")
);

const DashboardVisitasAgrupados = asyncComponent(() =>
  import("./dashboard/visitasAgrupados")
);

const ComitesNuevo = asyncComponent(() => import("./comiteNuevo"));

const DashboardConvencidosIndex = asyncComponent(() =>
  import("./dashboard/convencidosIndex")
);
const DashboardConvencidosLocal = asyncComponent(() =>
  import("./dashboard/convencidosLocal")
);
const DashboardConvencidosSeccion = asyncComponent(() =>
  import("./dashboard/convencidosSeccion")
);

const DashboardRedesIndex = asyncComponent(() =>
  import("./dashboard/redesIndex")
);
const DashboardRedesLocal = asyncComponent(() =>
  import("./dashboard/redesLocal")
);
const DashboardRedesSeccion = asyncComponent(() =>
  import("./dashboard/redesSeccion")
);

const DashboardVotosIndex = asyncComponent(() =>
  import("./dashboard/votosIndex")
);
const DashboardVotosLocal = asyncComponent(() =>
  import("./dashboard/votosLocal")
);
const DashboardVotosSeccion = asyncComponent(() =>
  import("./dashboard/votosSeccion")
);

const DashboardRedes = asyncComponent(() => import("./dashboard/redes"));
const DashboardVotos = asyncComponent(() => import("./dashboard/votos"));

const Validations = asyncComponent(() => import("./validations/index"));
const ValidationDetail = asyncComponent(() => import("./validations/detail"));
const ValidationDetail2 = asyncComponent(() => import("./validations/detail2"));

const Simpatizantes = asyncComponent(() => import("./simpatizante/index"));
const SimpatizanteDetail = asyncComponent(() =>
  import("./simpatizante/detail")
);
const SimpatizanteVisita = asyncComponent(() =>
  import("./simpatizante/visita")
);
const Estadistica = asyncComponent(() => import("./estadistica"));
const EstadisticaVisitas = asyncComponent(() =>
  import("./estadistica/capturas")
);

const Users = asyncComponent(() => import("./usuario/index"));
const UsersDetail = asyncComponent(() => import("./usuario/detail"));
const UsersAuthDetail = asyncComponent(() => import("./usuario/authDetail"));

const Secciones = asyncComponent(() => import("./seccion/index"));
const SeccionDetail = asyncComponent(() => import("./seccion/detail"));

const Distritos = asyncComponent(() => import("./distrito/index"));
const DistritoDetail = asyncComponent(() => import("./distrito/detail"));

const DistritosFederales = asyncComponent(() =>
  import("./distritoFederal/index")
);
const DistritoFederalDetail = asyncComponent(() =>
  import("./distritoFederal/detail")
);

const Comites = asyncComponent(() => import("./comite/index"));
const ComiteDetail = asyncComponent(() => import("./comite/detail"));

const Ayuda = asyncComponent(() => import("./ayuda/index"));

const Visitas = asyncComponent(() => import("./visita/index"));
const VisitaDetail = asyncComponent(() => import("./visita/detail"));

const Cots = asyncComponent(() => import("./cot/index"));
const CotDetail = asyncComponent(() => import("./cot/detail"));

const Grupos = asyncComponent(() => import("./grupos/index"));
const GruposDetail = asyncComponent(() => import("./grupos/detail"));
const Etiquetas = asyncComponent(() => import("./grupos/tags"));
const EtiquetasDetail = asyncComponent(() => import("./grupos/tagDetail"));

const Noticias = asyncComponent(() => import("./noticias/index"));
const NoticiaDetail = asyncComponent(() => import("./noticias/detail"));

const Capacitacion = asyncComponent(() => import("./defensaVoto/capacitacion"));
const CapacitacionDetail = asyncComponent(() =>
  import("./defensaVoto/capacitacionDetail")
);
const Casillas = asyncComponent(() => import("./defensaVoto/casillas"));
const CasillasDetail = asyncComponent(() =>
  import("./defensaVoto/casillasDetail")
);
const DiaD = asyncComponent(() => import("./defensaVoto/diad"));
const DiaDDetail = asyncComponent(() => import("./defensaVoto/diadDetail"));
const Incidencias = asyncComponent(() => import("./defensaVoto/incidencias"));
const IncidenciasDetail = asyncComponent(() =>
  import("./defensaVoto/incidenciasDetail")
);
const Representantes = asyncComponent(() =>
  import("./defensaVoto/representantes")
);
const RepresentantesCRGDetail = asyncComponent(() =>
  import("./defensaVoto/representantesCRGDetail")
);
const RepresentantesRCDetail = asyncComponent(() =>
  import("./defensaVoto/representantesRCDetail")
);
const RepresentantesRGDetail = asyncComponent(() =>
  import("./defensaVoto/representantesRGDetail")
);
const Votacion = asyncComponent(() => import("./defensaVoto/votacion"));
const VotacionDetail = asyncComponent(() =>
  import("./defensaVoto/votacionDetail")
);

const Materiales = asyncComponent(() => import("./materiales/index"));
const MaterialDetail = asyncComponent(() => import("./materiales/detail"));

const Solicitudes = asyncComponent(() => import("./solicitudes/index"));
const SolicitudDetail = asyncComponent(() => import("./solicitudes/detail"));

const Logs = asyncComponent(() => import("./logs/index"));

const AvisoPrivacidad = asyncComponent(() => import("./aviso"));
const Apps = asyncComponent(() => import("./apps"));
const Auth = asyncComponent(() => import("./auth/index"));
const Perfil = asyncComponent(() => import("./auth/perfil"));
const AuthToken = asyncComponent(() => import("./auth/token"));

const Reportes = asyncComponent(() => import("./reportes/index"));
const ReportesVisitas = asyncComponent(() => import("./reportes/visitas"));
const ReportesVisitasGeneral = asyncComponent(() =>
  import("./reportes/general")
);
const ReportesVisitasManuales = asyncComponent(() =>
  import("./reportes/manuales")
);
const Login = asyncComponent(() => import("./login"));
const Information = asyncComponent(() => import("./information"));
const Logout = asyncComponent(() => import("./logout"));
const NotFound = asyncComponent(() => import("./404"));
const Dev = asyncComponent(() => import("./dev/index"));
const DevDetail = asyncComponent(() => import("./dev/detail"));

const SegundaVisita = asyncComponent(() => import("./segundaVisita"));
const NuevaSegundaVisita = asyncComponent(() => import("./nuevaSegundaVisita"));

export {
  Home,
  NewHome,
  Auth,
  AuthToken,
  Apps,
  Ayuda,
  AvisoPrivacidad,
  Dasboard,
  Permissions,
  SinTerminar,
  ImportarVisita,
  Estadistica,
  EstadisticaVisitas,
  NewVisit,
  Dasboardr,
  GenerarReporte,
  GenerarReporteComite,
  ComitesNuevo,
  DasboardDistrito,
  DasboardSeccion,
  DashboardDetail,
  DashboardComitesAgrupados,
  DashboardVisitasAgrupados,
  DashboardConsolidado,
  DashboardComiteIndex,
  DashboardComiteLocal,
  DashboardComiteSeccion,
  DashboardConvencidosIndex,
  DashboardConvencidosLocal,
  DashboardConvencidosSeccion,
  DashboardRedesIndex,
  DashboardRedesLocal,
  DashboardRedesSeccion,
  DashboardVotosIndex,
  DashboardVotosLocal,
  DashboardVotosSeccion,
  DashboardRedes,
  DashboardVotos,
  Buscador,
  Validations,
  ValidationDetail,
  ValidationDetail2,
  Distritos,
  DistritoDetail,
  DistritosFederales,
  DistritoFederalDetail,
  Comites,
  ComiteDetail,
  Cots,
  CotDetail,
  Login,
  Grupos,
  GruposDetail,
  Etiquetas,
  EtiquetasDetail,
  Noticias,
  NoticiaDetail,
  Capacitacion,
  CapacitacionDetail,
  Casillas,
  CasillasDetail,
  DiaD,
  DiaDDetail,
  Incidencias,
  IncidenciasDetail,
  Representantes,
  RepresentantesCRGDetail,
  RepresentantesRCDetail,
  RepresentantesRGDetail,
  Votacion,
  Materiales,
  MaterialDetail,
  Solicitudes,
  SolicitudDetail,
  Logs,
  Secciones,
  SeccionDetail,
  Perfil,
  Reportes,
  ReportesVisitas,
  ReportesVisitasGeneral,
  ReportesVisitasManuales,
  MonitoreoCallcenter,
  ReportesCallcenter,
  Simpatizantes,
  SimpatizanteDetail,
  SimpatizanteVisita,
  Visitas,
  VisitaDetail,
  Users,
  UsersDetail,
  UsersAuthDetail,
  Logout,
  Dev,
  DevDetail,
  SegundaVisita,
  NuevaSegundaVisita,
  NotFound,
  Information
};
