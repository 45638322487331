import firebase from "../firebase";

const url = "https://api.sireson.com";
// const url = "http://localhost:8080";

const GET = "GET";
const POST = "POST";

let esc = encodeURIComponent;
const paramsToQuery = (params) =>
  Object.keys(params)
    .map((key) => esc(key) + "=" + esc(params[key]))
    .join("&");

const headers = (token) => ({
  "Content-Type": "application/json",
  Accept: "application/json",
  Authorization: `Bearer ${token}`,
});

const procesarRespuesta = (response) => {
  if (response.status >= 300) {
    return Promise.reject(response);
  }
  return response.json();
};

const Api = {
  async _get(uri, params, config = {}) {
    const token = await firebase.auth().currentUser.getIdToken();
    const c = {
      method: GET,
      headers: headers(token),
      ...config,
    };
    return fetch(`${url}${uri}${params ? `?${paramsToQuery(params)}` : ""}`, c);
  },
  async _post(uri, body, config = {}) {
    const token = await firebase.auth().currentUser.getIdToken();
    const c = {
      method: POST,
      headers: headers(token),
      body: JSON.stringify(body),
    };
    return fetch(`${url}${uri}`, c);
  },
  getAuthToken() {
    return firebase.auth().currentUser.getIdToken();
  },
  async obtenerTags(params) {
    return this._get("/v1/tag", params).then(procesarRespuesta);
  },
  async guardarTag(body) {
    return this._post("/v1/tag/guardar", body).then(procesarRespuesta);
  },
  async obtenerDistritos(params) {
    return this._get("/v1/distrito", params).then(procesarRespuesta);
  },
  async obtenerDistritosReal(params) {
    return this._get("/v1/distrito/real", params).then(procesarRespuesta);
  },
  async obtenerDistritosTodos(params) {
    return this._get("/v1/distrito/lista", params).then(procesarRespuesta);
  },
  async obtenerSecciones(params) {
    return this._get("/v1/seccion", params).then(procesarRespuesta);
  },
  async obtenerComites(params) {
    return this._get("/v1/comite", params).then(procesarRespuesta);
  },
  async obtenerEtiquetaComite(params) {
    return this._get("/v1/tag/comites", params).then(procesarRespuesta);
  },
  async obtenerEstadisticaConvencidos(params) {
    return this._get("/v1/estadistica/capturas", params).then(
      procesarRespuesta
    );
  },
  async obtenerEstadisticaConvencidosExcel(params) {
    return this._get("/v1/estadistica/capturas-excel", params).then(
      procesarRespuesta
    );
  },
  async obtenerEstadisticaConvencidosExcelPromotor(params) {
    return this._get("/v1/estadistica/capturas-excel-promotor", params).then(
      procesarRespuesta
    );
  },
  async obtenerEstadisticaComites(params) {
    return this._get("/v1/estadistica/comites", params).then(procesarRespuesta);
  },
  async obtenerEstadisticaComitesExcel(params) {
    return this._get("/v1/estadistica/comites-excel", params).then(
      procesarRespuesta
    );
  },
  async obtenerEstadisticaValidaciones(params) {
    return this._get("/v1/estadistica/validaciones", params).then(
      procesarRespuesta
    );
  },
  async obtenerEstadisticaSegundasVisitas(params) {
    return this._get("/v1/estadistica/segunda-visita", params).then(
      procesarRespuesta
    );
  },
  async obtenerEstadisticaValidacionesExcel(params) {
    return this._get("/v1/estadistica/validaciones-excel", params).then(
      procesarRespuesta
    );
  },
  async obtenerReporteGeneralCallcenter(params) {
    return this._get("/v1/visita/reporte-general", params).then(
      procesarRespuesta
    );
  },
  async obtenerEtiquetaVisitas(params) {
    return this._get("/v1/tag/visitas", params).then(procesarRespuesta);
  },
  async obtenerGrupoComite(params) {
    return this._get("/v1/grupo/comites", params).then(procesarRespuesta);
  },
  async obtenerVisitasAgrupados(params) {
    return this._get("/v1/csv/grupo-visitas", params).then(procesarRespuesta);
  },
  async obtenerEstados(params) {
    return this._get("/v1/estado", params).then(procesarRespuesta);
  },
  async validarINE(params) {
    return this._get("/v1/usuario/validar-ine", params).then(procesarRespuesta);
  },
  async obtenerMunicipios(params) {
    return this._get("/v1/municipio", params).then(procesarRespuesta);
  },
  async obtenerGrupo(params) {
    return this._get("/v1/grupo", params).then(procesarRespuesta);
  },
  async obtenerComitesNuevo(params) {
    return this._get("/v1/comites", params).then(procesarRespuesta);
  },
  async obtenerComitesSimpatizantes(params) {
    return this._get("/v1/comites/simpatizantes", params).then(
      procesarRespuesta
    );
  },
  async guardarGrupo(body) {
    return this._post("/v1/grupo/guardar", body).then(procesarRespuesta);
  },
  async eliminarGrupo(body) {
    return this._post("/v1/grupo/eliminar", body).then(procesarRespuesta);
  },
  async eliminarVisitaReal(body) {
    return this._post("/v1/visita/eliminar", body).then(procesarRespuesta);
  },
  async eliminarVisitasCelular(body) {
    return this._post("/v1/visita/eliminar-visitas-celular", body).then(procesarRespuesta);
  },
  async obtenerVisitas(params) {
    return this._get("/v1/visita/index", params).then(procesarRespuesta);
  },
  async obtenerVisitas2(params) {
    return this._get("/v1/visita/index2", params).then(procesarRespuesta);
  },
  async obtenerMonitoreoCallcenter(params) {
    return this._get("/v1/visita/monitoreo", params).then(procesarRespuesta);
  },
  async obtenerReporteCallcenter(params) {
    return this._get("/v1/visita/reporte-callcenter", params).then(
      procesarRespuesta
    );
  },
  async obtenerSegundaVisitas(params) {
    return this._get("/v1/visita/segunda-visita", params).then(
      procesarRespuesta
    );
  },
  async guardarSegundaVisita(body) {
    return this._post("/v1/visita/guardar-segunda-visita", body).then(
      procesarRespuesta
    );
  },
  async obtenerVisitasReporte(params) {
    return this._get("/v1/visita/reporte", params).then(procesarRespuesta);
  },
  async guardarRepresentanteDf(body) {
    return this._post("/v1/authdf/guardar", body).then(procesarRespuesta);
  },
  async guardarVisita(body) {
    return this._post("/v1/visita/guardar", body).then(procesarRespuesta);
  },
  async obtenerUsuarios(params) {
    return this._get("/v1/usuario", params).then(procesarRespuesta);
  },
  async obtenerCreadores(params) {
    return this._get("/v1/auth/lista", params).then(procesarRespuesta);
  },
  async obtenerUsuarioPorIne(params) {
    return this._get("/v1/usuario/porine", params).then(procesarRespuesta);
  },
  async guardarUsuario(params) {
    return this._post("/v1/usuario/guardar", params).then(procesarRespuesta);
  },
  async obtenerUsuariosAuth(params) {
    return this._get("/v1/auth", params).then(procesarRespuesta);
  },
  async obtenerRoles(params) {
    return this._get("/v1/auth/roles", params).then(procesarRespuesta);
  },
  async obtenerPerfil(params) {
    return this._get("/v1/perfil", params).then(procesarRespuesta);
  },
  async guardarAuth(body) {
    return this._post("/v1/auth/guardar", body).then(procesarRespuesta);
  },
  async guardarUsuarioEnComite(idUsuario, idComite, tipo) {
    let body = {
      idUsuario: idUsuario,
      idComite: idComite,
    };
    if (tipo !== undefined) {
      body["tipo"] = tipo;
    }
    return this._post("/v1/comite/agregar-usuario", body).then(
      procesarRespuesta
    );
  },

  async guardarVisita(body) {
    return this._post("/v1/visita/guardar", body).then(procesarRespuesta);
  },

  async bloquearUsuario(idUsuario, comment = null) {
    let params = {
      uid: idUsuario,
    };
    if (comment) {
      params = { ...params, comentario: comment };
    }
    return this._post("/v1/auth/bloquear", params).then(procesarRespuesta);
  },

  async activarUsuario(idUsuario) {
    let params = {
      uid: idUsuario,
    };
    return this._post("/v1/auth/activar", params).then(procesarRespuesta);
  },

  async guardarDistrito(distrito) {
    return this._post("/v1/distrito/guardar", distrito).then(
      procesarRespuesta
    ); /**/
  },

  async eliminarUsuarioDeComite(idUsuario, idComite) {
    let body = {
      idUsuario: idUsuario,
      idComite: idComite,
    };
    return this._post("/v1/comite/eliminar-usuario", body).then(
      procesarRespuesta
    );
  },

  async crearComite(params) {
    return this._post("/v1/comite/guardar", params).then(procesarRespuesta);
  },

  async guardarSeccion(params) {
    return this._post("/v1/seccion/guardar", params).then(procesarRespuesta);
  },

  async eliminarSeccion(params) {
    return this._post("/v1/seccion/eliminar", params).then(procesarRespuesta);
  },

  async eliminarAuthusuarioporuid(params) {
    return this._post("/v1/auth/eliminar", params).then(procesarRespuesta);
  },
};

export default Api;
