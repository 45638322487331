import React from "react";
import { Pie } from "react-chartjs-2";
import { Grid, Typography } from "@material-ui/core";
import { useHistory, useParams } from "react-router-dom";

const PieCharts = (props) => {
  const { distritos, dataLabels, campo, fid, baseUrl, nombre } = props;

  const history = useHistory();

  return (
    <Grid container spacing={2}>
      {distritos.map((distrito, i) => {
        const federal = distrito.tipo === 1;
        const pushTo = `${federal ? "local" : `local/${fid}`}`;
        const dataBar = {
          labels: dataLabels,
          datasets: [
            {
              data: [distrito[campo], 100 - distrito[campo]],
              backgroundColor: ["#940006", "#eaeaea"],
              hoverBackgroundColor: ["#940006", "#eaeaea"],
            },
          ],
        };
        const totalPorcentaje = 100;
        return (
          <Grid
            key={`dist-${distrito.tipo}-${i}`}
            style={{ marginBottom: 25 }}
            item
            xs={12}
            sm={6}
            md={4}
            onClick={() => {
              history.push(`${baseUrl}/${pushTo}/${distrito.id}`);
            }}
          >
            <Typography variant="h6" style={{ textAlign: "center" }}>
              Distrito {federal && "Federal"} {distrito.nombre}{" "}
            </Typography>
            <Pie data={dataBar} />
            <Typography style={{ textAlign: "center" }}>
              Porcentaje: <strong>{distrito[campo].toFixed(2)}%</strong>
            </Typography>
            <Typography style={{ textAlign: "center" }}>
              Restante:{" "}
              <strong>{(totalPorcentaje - distrito[campo]).toFixed(2)}%</strong>
            </Typography>
          </Grid>
        );
      })}
    </Grid>
  );
};

export default PieCharts;
