import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  root: {
    height:'200px',
    alignItems:'center',
    display: 'flex',
    justifyContent: 'center',
    '&.fixed': {
      height: "fit-content",
      position: "fixed!important",
      top: "36%",
      left: "44%"
    }
  }
}));

const LoadingIndicator = ({ fixed, hide }) => {
  const classes = useStyles();
  return (
    <div 
      style={{ display: (hide? 'none' : 'flex') }}
      className={`${classes.root} ${fixed? 'fixed' : ''}`}>
        <Dialog open={true}>
          <DialogContent style={{padding: 10}}>
            <CircularProgress size={fixed? 100 : undefined} />
          </DialogContent>
        </Dialog>
    </div>
  )
};

export default LoadingIndicator;