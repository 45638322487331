import React from "react";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  withStyles,
} from "@material-ui/core";
import HighchartsReact from "highcharts-react-official";
import Highcharts, { isNumber } from "highcharts";
import HighchartsExporting from "highcharts/modules/exporting";
import highcharts3d from "highcharts/highcharts-3d";

highcharts3d(Highcharts);

if (typeof Highcharts === "object") {
  HighchartsExporting(Highcharts);
}

function numberWithCommas(x) {
  if (!x) return 0;
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const GraphWithTable = ({
  // estadistica,
  values,
  // tableData = [],
  // headers = [],
  // fields = [],
  // fieldName = null,
}) => {
  // const renderValue = (key, value) => {
  //   function isFunction(functionToCheck) {
  //     return (
  //       functionToCheck &&
  //       {}.toString.call(functionToCheck) === "[object Function]"
  //     );
  //   }

  //   // if (isFunction(value)) return value();
  //   if (isNumber(value)) return numberWithCommas(value);
  //   else if (key !== "count") return <strong>{value}</strong>;
  // };

  const conComite =
    values &&
    values.series[0].table &&
    values.series[0].table.some((e) => e.comites);

  return (
    <>
      <HighchartsReact highcharts={Highcharts} options={values} />
      <TableContainer component={Paper}>
        <Table size="small">
          <TableHead>
            <TableRow>
              <StyledTableCell>
                <strong>NOMBRE</strong>
              </StyledTableCell>
              {conComite && (
                <StyledTableCell>
                  <strong>COMITES</strong>
                </StyledTableCell>
              )}
              <StyledTableCell algin="right">
                <strong>TOTAL</strong>
              </StyledTableCell>

              {/* {extras.length > 0 && ()} */}
            </TableRow>
          </TableHead>
          <TableBody>
            {values.series[0].table
              .sort((a, b) => b.count - a.count)
              .map((elemento, idx) => (
                <StyledTableRow key={idx}>
                  <StyledTableCell>
                    <strong>
                      {elemento.nombre === "FINALIZADA"
                        ? "APROBADA"
                        : elemento.nombre}
                    </strong>
                  </StyledTableCell>
                  {conComite && (
                    <StyledTableCell>
                      {numberWithCommas(parseInt(elemento.comites))}
                    </StyledTableCell>
                  )}
                  <StyledTableCell align="right">
                    {numberWithCommas(parseInt(elemento.count))}
                  </StyledTableCell>
                </StyledTableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default GraphWithTable;
