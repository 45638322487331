import React, { useContext, useEffect, useState } from "react";
import "./styles.css";
import {
  BrowserRouter as Router,
  NavLink,
  Redirect,
  Route,
} from "react-router-dom";
import theme from "./components/Theme";
import { ThemeProvider } from "@material-ui/core/styles";
import RouteAuth from "./components/RouteAuth";
import { useSelector } from "react-redux";
import { isLoaded, isEmpty } from "react-redux-firebase";
import { ROL } from "./constants";

import firebase from "firebase";
/**
 * Se importa logo para que no de un error de imagen aunque no se usa
 */
import logo from "./assets/images/logo.png";
import * as v from "./views";
import Api from "./utils/Api";
import PerfilContext from "./context/perfil/perfilContext";
import Layout from "./views/layout";
import {
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  Grid,
  Paper,
  Typography,
} from "@material-ui/core";
import { set } from "date-fns";

const obtenerPerfil = () => {
  return new Promise((resolve, reject) => {
    Api.obtenerPerfil({ expand: "grupos,grupos.tag" })
      .then((data) => {
        resolve(
          (data.resultado && data.resultado.length && data.resultado[0]) || {}
        );
      })
      .catch((error) => reject(error));
  });
};

export default () => {
  const auth = useSelector((state) => state.firebase.auth);
  const { perfil, setPerfil } = useContext(PerfilContext);
  const [token, setToken] = useState("");

  useEffect(() => {
    // setIncompleto(true);
  }, []);

  useEffect(() => {
    if (firebase.auth().currentUser) {
      firebase
        .auth()
        .currentUser.getIdToken()
        .then((e) => {
          setToken(e);
        });
    }

    if (isLoaded(auth)) {
      if (isEmpty(auth)) {
        setPerfil({});
      } else {
        obtenerPerfil()
          .then((perfil) => {
            const {
              correo,
              celular,
              nombre,
              apellidoPaterno,
              apellidoMaterno,
              claveElector,
            } = perfil;

            setPerfil(perfil);
            // console.log(perfil);
            // if (
            //   (correo && correo === "") ||
            //   (celular && celular === "") ||
            //   (nombre && nombre === "") ||
            //   (apellidoPaterno && apellidoPaterno === "") ||
            //   (apellidoMaterno && apellidoMaterno === "") ||
            //   (claveElector && claveElector === "")
            // )
            // console.log("incompleto true");
            // setIncompleto(true);
          })
          .catch((error) =>
            console.error("Error al consultar el perfil", error)
          );
      }
    }
  }, [auth]);

  if (!isLoaded(auth) || perfil === null) {
    return null;
  }

  const esAdmin =
    perfil.rol === ROL.SOPORTE ||
    perfil.rol === ROL.ADMIN ||
    perfil.rol === ROL.CANDIDATO ||
    perfil.rol === ROL.COORDINADOR_GENERAL;

  return (
    <>
      <ThemeProvider theme={theme}>
        <Router>
          <Route exact path="/login" component={v.Login} />
          <Route exact path="/informacion" component={v.Information} />
          <Route
            exact
            path="/aviso-privacidad"
            component={v.AvisoPrivacidad}
          />
          {perfil.rol <= 2 && (
            <RouteAuth exact path="/permisos/:uid" component={v.Permissions} />
          )}
          {perfil.rol <= 2 && (
            <RouteAuth
              exact
              path="/visita/importar"
              component={v.ImportarVisita}
            />
          )}
          {perfil.rol == null && (
            <RouteAuth exact path="/" component={v.Comites} requireAuth />
          )}
          {/*
          {esAdmin && (
            <>
              <RouteAuth exact path="/" component={v.Comites} requireAuth />
            </>
          )} */}
          {/* <RouteAuth exact path="/" component={v.Home} requireAuth /> */}

          {esAdmin && (
            <RouteAuth exact path="/" component={v.Comites} requireAuth />
          )}
          {perfil.rol === 9 && (
            <RouteAuth
              exact
              path="/"
              component={() => <Redirect to="/dashboard/comite" />}
              requireAuth
            />
          )}
          {/* {perfil.rol < ROL.PROMOTOR && (
            <RouteAuth exact path="/" component={v.NewHome} requireAuth />
          )} */}

          {perfil.rol === ROL.COORDINADOR_FEDERAL && (
            <RouteAuth exact path="/" component={v.Buscador} requireAuth />
          )}
          {perfil.rol == ROL.COORDINADOR_LOCAL && (
            <RouteAuth exact path="/" component={v.ComitesNuevo} />
          )}
          {perfil.rol == ROL.PROMOTOR && (
            <RouteAuth exact path="/" component={v.Ayuda} requireAuth />
          )}
          {perfil.rol == ROL.CALLCENTER && (
            <RouteAuth exact path="/" component={v.Validations} requireAuth />
          )}
          {perfil.permisos && (perfil.permisos.Buscador & 1) === 1 && (
            <RouteAuth
              exact
              path="/buscador"
              component={v.Buscador}
              requireAuth
            />
          )}
          {perfil.rol <= ROL.COORDINADOR_LOCAL && (
            <RouteAuth
              exact
              path="/comites"
              component={v.ComitesNuevo}
              requireAuth
            />
          )}
          {perfil.permisos && (perfil.permisos.Usuario & 1) === 1 && (
            <RouteAuth
              exact
              path="/token"
              component={() => (
                <Layout>
                  <Grid
                    container
                    component={Paper}
                    style={{
                      padding: "10px 17px 10px",
                      margin: "0 0 8px",
                      fontSize: "18px",
                      color: "#292929",
                      backgroundColor: "#ffffff",
                    }}
                  >
                    <Grid item xs={12}>
                      <Typography>{token}</Typography>
                    </Grid>
                  </Grid>
                </Layout>
              )}
            />
          )}
          <RouteAuth exact path="/apps" component={v.Apps} />

          {(perfil.rol <= 6 || perfil.correo !== "Chemeljm@yahoo.com.mx" && perfil.rol === 9) && (
            <RouteAuth
              exact
              path="/reportes"
              component={v.Reportes}
              requireAuth
            />
          )}
          {(perfil.rol <= 4 ||
            perfil.correo === "heriberto.secregral@gmail.com" 
            || perfil.correo === "Chemeljm@yahoo.com.mx") && (
              <RouteAuth
                exact
                path="/estadistica"
                component={v.Estadistica}
                requireAuth
              />
            )}
          {(perfil.rol > 4 && perfil.rol !== 8) && (
            <RouteAuth
              exact
              path="/estadistica-captura"
              component={v.EstadisticaVisitas}
              requireAuth
            />
          )}
          {(esAdmin || perfil.rol === 9) && (
            <RouteAuth
              exact
              path="/reportes/generar-comite"
              component={v.GenerarReporteComite}
              requireAuth
            />
          )}
          {(esAdmin || perfil.rol === 9) && (
            <RouteAuth
              exact
              path="/reportes/generar-visita"
              component={v.GenerarReporte}
              requireAuth
            />
          )}
          {perfil.permisos && (perfil.permisos.ReporteVisitaDia & 1) === 1 && (
            <RouteAuth
              exact
              path="/reportes/visitas"
              component={v.ReportesVisitas}
              requireAuth
            />
          )}
          {perfil.permisos &&
            (perfil.permisos.ReporteSimpatizantes & 1) === 1 && (
              <RouteAuth
                exact
                path="/reportes/general"
                component={v.ReportesVisitasGeneral}
                requireAuth
              />
            )}
          {perfil.rol <= 4 && (
            <RouteAuth
              exact
              path="/monitoreo/callcenter"
              component={v.MonitoreoCallcenter}
              requireAuth
            />
          )}
          {perfil.rol <= 4 && (
            <RouteAuth
              exact
              path="/reportes/callcenter"
              component={v.ReportesCallcenter}
              requireAuth
            />
          )}
          <RouteAuth
            exact
            path="/reportes/manuales"
            component={v.ReportesVisitasManuales}
            requireAuth
          />
          {perfil.permisos && (perfil.permisos.Ayuda & 1) === 1 && (
            <RouteAuth exact path="/ayuda" component={v.Ayuda} requireAuth />
          )}
          <RouteAuth exact path="/logout" component={v.Logout} requireAuth />
          {perfil.rol < ROL.PROMOTOR &&
            perfil.permisos &&
            (perfil.permisos.Usuario & 1) === 1 && (
              <RouteAuth
                exact
                path="/usuarios"
                component={v.Auth}
                requireAuth
              />
            )}
          {perfil.rol < ROL.PROMOTOR && (
            <RouteAuth
              exact
              path="/usuarios/:id"
              component={v.UsersAuthDetail}
              requireAuth
            />
          )}
          {(perfil.rol !== 9 && perfil.rol !== 8) && (
            <RouteAuth
              exact
              path="/visita"
              component={v.NewVisit}
              requireAuth
            />
          )}
          <RouteAuth
            exact
            path="/auth/perfil"
            component={v.Perfil}
            requireAuth
          />
          <RouteAuth
            exact
            path="/auth/token"
            component={v.AuthToken}
            requireAuth
          />
          {/* {perfil.rol < ROL.PROMOTOR && (
            <>
              <RouteAuth
                exact
                path="/dashboard"
                component={v.Dasboard}
                requireAuth
              />
              <RouteAuth
                exact
                path="/dashboardr"
                component={v.Dasboardr}
                requireAuth
              />
            </>
          )} */}

          {(perfil.rol <= ROL.COORDINADOR_FEDERAL ||
            perfil.rol == ROL.CALLCENTER) &&
            perfil.permisos &&
            (perfil.permisos.Validacion & 1) === 1 && (
              <RouteAuth
                exact
                path="/cc"
                component={v.Validations}
                requireAuth
              />
            )}
          {(perfil.rol <= ROL.COORDINADOR_FEDERAL ||
            perfil.rol == ROL.CALLCENTER) &&
            perfil.permisos &&
            (perfil.permisos.Validacion & 1) === 1 && (
              <RouteAuth
                exact
                path="/cc/detalle/:id"
                component={v.ValidationDetail}
                requireAuth
              />
            )}

           {(perfil.rol <= ROL.COORDINADOR_FEDERAL ||
            perfil.rol == ROL.CALLCENTER) &&
            perfil.permisos &&
            (perfil.permisos.Validacion & 1) === 1 && (
              <RouteAuth
                exact
                path="/cc/detalle2/:id"
                component={v.ValidationDetail2}
                requireAuth
              />
            )}

          {/* {perfil.permisos &&
            (perfil.permisos.AvanceComite & 1) === 1 && (
              <RouteAuth
                exact
                path="/dashboard/comite"
                component={v.Dasboard}
                requireAuth
              />
            )} */}

          {perfil.rol <= 2 && (
            <RouteAuth
              exact
              path="/dashboard/comites-grupo"
              component={v.DashboardComitesAgrupados}
              requireAuth
            />
          )}

          {perfil.rol <= 2 && (
            <RouteAuth
              exact
              path="/dashboard/visitas-grupo"
              component={v.DashboardVisitasAgrupados}
              requireAuth
            />
          )}

          {(perfil.rol <= 5 || perfil.rol === 9) && (
            <RouteAuth
              exact
              path="/dashboard/comite"
              component={v.DashboardComiteIndex}
              requireAuth
            />
          )}

          {(perfil.rol <= 5 || perfil.rol === 9) && (
            <RouteAuth
              exact
              path="/dashboard/comite/local/:fid"
              component={v.DashboardComiteLocal}
              requireAuth
            />
          )}
          {(perfil.rol <= 5 || perfil.rol === 9) && (
            <RouteAuth
              exact
              path="/dashboard/comite/local/:fid/:lid"
              component={v.DashboardComiteSeccion}
              requireAuth
            />
          )}
          {(perfil.rol <= 6 || perfil.rol === 9) && (
            <RouteAuth
              exact
              path="/dashboard/convencidos"
              component={v.DashboardConvencidosIndex}
              requireAuth
            />
          )}
          {(perfil.rol <= 6 || perfil.rol === 9) && (
            <RouteAuth
              exact
              path="/dashboard/convencidos/local/:fid"
              component={v.DashboardConvencidosLocal}
              requireAuth
            />
          )}
          {(perfil.rol <= 6 || perfil.rol === 9) && (
            <RouteAuth
              exact
              path="/dashboard/convencidos/local/:fid/:lid"
              component={v.DashboardConvencidosSeccion}
              requireAuth
            />
          )}

          {esAdmin && (
            <>
              <RouteAuth
                exact
                path="/comite/detalle/:id"
                component={v.ComiteDetail}
                requireAuth
              />
              <RouteAuth
                exact
                path="/dashboard/local"
                component={v.DasboardDistrito}
                requireAuth
              />
              <RouteAuth
                exact
                path="/dashboard/seccion/:id"
                component={v.DasboardSeccion}
                requireAuth
              />
              <RouteAuth
                exact
                path="/dashboard/comites/:id"
                component={v.DashboardDetail}
                requireAuth
              />
              <RouteAuth
                exact
                path="/dashboard/consolidado"
                component={v.DashboardConsolidado}
                requireAuth
              />

              {perfil.permisos && (perfil.permisos.AvanceRedes & 1) === 1 && (
                <RouteAuth
                  exact
                  path="/dashboard/avance-redes"
                  component={v.DashboardRedesIndex}
                  requireAuth
                />
              )}
              {perfil.permisos && (perfil.permisos.AvanceRedes & 1) === 1 && (
                <RouteAuth
                  exact
                  path="/dashboard/avance-redes/local/:fid"
                  component={v.DashboardRedesLocal}
                  requireAuth
                />
              )}
              {perfil.permisos && (perfil.permisos.AvanceRedes & 1) === 1 && (
                <RouteAuth
                  exact
                  path="/dashboard/avance-redes/local/:fid/:lid"
                  component={v.DashboardRedesSeccion}
                  requireAuth
                />
              )}

              {perfil.permisos &&
                (perfil.permisos.AvanceDefensaVoto & 1) === 1 && (
                  <RouteAuth
                    exact
                    path="/dashboard/avance-votos"
                    component={v.DashboardVotosIndex}
                    requireAuth
                  />
                )}
              {perfil.permisos &&
                (perfil.permisos.AvanceDefensaVoto & 1) === 1 && (
                  <RouteAuth
                    exact
                    path="/dashboard/avance-votos/local/:fid"
                    component={v.DashboardVotosLocal}
                    requireAuth
                  />
                )}
              {perfil.permisos &&
                (perfil.permisos.AvanceDefensaVoto & 1) === 1 && (
                  <RouteAuth
                    exact
                    path="/dashboard/avance-votos/local/:fid/:lid"
                    component={v.DashboardVotosSeccion}
                    requireAuth
                  />
                )}
              <RouteAuth exact path="/cots" component={v.Cots} requireAuth />
              <RouteAuth
                exact
                path="/cot/detalle/:id"
                component={v.CotDetail}
                requireAuth
              />
              {perfil.permisos && (perfil.permisos.Simpatizantes & 1) === 1 && (
                <>
                  <RouteAuth
                    exact
                    path="/simpatizantes"
                    component={v.Simpatizantes}
                    requireAuth
                  />
                  <RouteAuth
                    exact
                    path="/simpatizante/detalle/:id"
                    component={v.SimpatizanteDetail}
                    requireAuth
                  />
                  <RouteAuth
                    exact
                    path="/simpatizante/detalle/:id/visita"
                    component={v.SimpatizanteVisita}
                    requireAuth
                  />
                </>
              )}

              <RouteAuth
                exact
                path="/visitas"
                component={v.Visitas}
                requireAuth
              />
              <RouteAuth
                exact
                path="/visita/detalle/:id"
                component={v.VisitaDetail}
                requireAuth
              />
              {perfil.permisos && (perfil.permisos.Materiales & 1) === 1 && (
                <RouteAuth
                  exact
                  path="/materiales"
                  component={v.Materiales}
                  requireAuth
                />
              )}
              {perfil.permisos && (perfil.permisos.Materiales & 1) === 1 && (
                <RouteAuth
                  exact
                  path="/materiales/detalle/:id"
                  component={v.MaterialDetail}
                  requireAuth
                />
              )}
              {perfil.permisos &&
                (perfil.permisos.AdministracionCambioINE & 1) === 1 && (
                  <RouteAuth
                    exact
                    path="/solicitudes"
                    component={v.Solicitudes}
                    requireAuth
                  />
                )}
              {perfil.permisos &&
                (perfil.permisos.AdministracionCambioINE & 1) === 1 && (
                  <RouteAuth
                    exact
                    path="/solicitudes/detalle/:id"
                    component={v.SolicitudDetail}
                    requireAuth
                  />
                )}
              <RouteAuth exact path="/logs" component={v.Logs} requireAuth />

              {perfil.permisos && (
                <RouteAuth
                  exact
                  path="/grupos"
                  component={v.Grupos}
                  requireAuth
                />
              )}

              {perfil.permisos && (perfil.permisos.Grupos & 1) === 1 && (
                <RouteAuth
                  exact
                  path="/grupos/detalle/:id"
                  component={v.GruposDetail}
                  requireAuth
                />
              )}
              {perfil.permisos && (perfil.permisos.Noticias & 1) === 1 && (
                <RouteAuth
                  exact
                  path="/noticias"
                  component={v.Noticias}
                  requireAuth
                />
              )}
              {perfil.permisos && (perfil.permisos.Grupos & 1) === 1 && (
                <RouteAuth
                  exact
                  path="/grupos/etiquetas"
                  component={v.Etiquetas}
                  requireAuth
                />
              )}
              {perfil.permisos && (perfil.permisos.Grupos & 1) === 1 && (
                <RouteAuth
                  exact
                  path="/grupos/etiquetas/:id"
                  component={v.EtiquetasDetail}
                  requireAuth
                />
              )}
              {perfil.permisos && (perfil.permisos.Noticias & 1) === 1 && (
                <RouteAuth
                  exact
                  path="/noticia/detalle/:id"
                  component={v.NoticiaDetail}
                  requireAuth
                />
              )}
              {perfil.permisos &&
                (perfil.permisos.AdministracionSecciones & 1) === 1 && (
                  <RouteAuth
                    exact
                    path="/secciones"
                    component={v.Secciones}
                    requireAuth
                  />
                )}
              {perfil.permisos &&
                (perfil.permisos.AdministracionSecciones & 1) === 1 && (
                  <RouteAuth
                    exact
                    path="/seccion/detalle/:id"
                    component={v.SeccionDetail}
                    requireAuth
                  />
                )}
              {perfil.permisos &&
                (perfil.permisos.AdministracionDistritosLocales & 1) === 1 && (
                  <RouteAuth
                    exact
                    path="/distritos-locales"
                    component={v.Distritos}
                    requireAuth
                  />
                )}
              {perfil.permisos &&
                (perfil.permisos.AdministracionDistritosLocales & 1) === 1 && (
                  <RouteAuth
                    exact
                    path="/distrito-local/detalle/:id"
                    component={v.DistritoDetail}
                    requireAuth
                  />
                )}
              {perfil.permisos &&
                (perfil.permisos.AdministracionDistritosFederales & 1) ===
                  1 && (
                  <RouteAuth
                    exact
                    path="/distritos-federales"
                    component={v.DistritosFederales}
                    requireAuth
                  />
                )}
              {perfil.permisos &&
                (perfil.permisos.AdministracionDistritosFederales & 1) ===
                  1 && (
                  <RouteAuth
                    exact
                    path="/distrito-federal/detalle/:id"
                    component={v.DistritoFederalDetail}
                    requireAuth
                  />
                )}
            </>
          )}

          {perfil.rol <= 3 && (
            <>
              <RouteAuth
                exact
                path="/casillas"
                component={v.Casillas}
                requireAuth
              />
              <RouteAuth
                exact
                path="/casillas/:id"
                component={v.CasillasDetail}
                requireAuth
              />
              <RouteAuth
                exact
                path="/representantes"
                component={v.Representantes}
                requireAuth
              />
              <RouteAuth
                exact
                path="/representantes/crg/:id"
                component={v.RepresentantesCRGDetail}
                requireAuth
              />
              <RouteAuth
                exact
                path="/representantes/rg/:id"
                component={v.RepresentantesRGDetail}
                requireAuth
              />
              <RouteAuth
                exact
                path="/representantes/rc/:id"
                component={v.RepresentantesRCDetail}
                requireAuth
              />
              <RouteAuth
                exact
                path="/capacitacion"
                component={v.Capacitacion}
                requireAuth
              />
              <RouteAuth
                exact
                path="/incidencias"
                component={v.Incidencias}
                requireAuth
              />
              <RouteAuth exact path="/dia-d" component={v.DiaD} requireAuth />
              <RouteAuth
                exact
                path="/votacion"
                component={v.Votacion}
                requireAuth
              />
            </>
          )}

          {(perfil.rol <= ROL.COORDINADOR_LOCAL || perfil.rol === 9) &&
            perfil.permisos &&
            (perfil.permisos.ErroresVisita & 1) === 1 && (
              <>
                <RouteAuth
                  exact
                  path="/reportes/error"
                  component={v.Dev}
                  requireAuth
                />
                <RouteAuth
                  exact
                  path="/reportes/sin-terminar"
                  component={v.SinTerminar}
                  requireAuth
                />
                <RouteAuth
                  exact
                  path="/reportes/error/:id"
                  component={v.DevDetail}
                  requireAuth
                />
              </>
            )}
          {/* <RouteAuth
            exact
            path="/segunda-visita"
            component={v.SegundaVisita}
            requireAuth
          /> */}
          {perfil.rol !== 9 && perfil.rol !== 8  && ( 
            <RouteAuth
              exact
              path="/segunda-visita/:id"
              component={v.NuevaSegundaVisita}
              requireAuth
            />
          )}
          
        </Router>
      </ThemeProvider>
    </>
  );
};
