import React, {
  useState,
  createContext,
  useContext,
  useEffect,
  useMemo,
} from "react";
import Api from "../utils/Api";
import { ROL } from "../constants";
import { isLoaded as isFirebaseLoaded, isEmpty } from "react-redux-firebase";
import PerfilContext from "../context/perfil/perfilContext";
import { auth } from "firebase/app";

const AppContext = createContext();

export function AppProvider(props) {
  const [distrito, setDistrito] = useState(null);
  const [distritos, setDistritos] = useState([]);
  const [distritosLoading, setDistritosLoading] = useState(false);
  const [seccion, setSeccion] = useState(null);
  const [secciones, setSecciones] = useState([]);
  const [seccionesLoading, setSeccionesLoading] = useState(false);
  const [promotor, setPromotor] = useState(null);
  const [promotores, setPromotores] = useState([]);
  const [promotoresLoading, setPromotoresLoading] = useState(false);
  const { perfil } = useContext(PerfilContext);

  useEffect(() => {
    auth().onAuthStateChanged((user) => {
      if (user && perfil) getDistritos();
    });
  }, [perfil]);

  async function getDistritos(extraParams = null) {
    try {
      setDistritosLoading(true);
      let params = {
        limite: -1,
        fields: "id,nombre,tipo",
        expand: "distritos,distrito",
        ordenar: "nombre.asc",
      };
      let merge = { ...params };
      if (extraParams) merge = { ...merge, ...extraParams };
      let resp = {};
      if (perfil.rol === ROL.CALLCENTER)
        resp = await Api.obtenerDistritos({
          expand: "distritos",
          ordenar: "nombre.asc",
        });
      else resp = await Api.obtenerDistritos(merge);
      if (resp.resultado !== undefined && resp.resultado.length) {
        setDistritos(resp.resultado);
      }
    } catch (error) {
      alert(error);
    } finally {
      setDistritosLoading(false);
    }
  }

  async function getSecciones(extraParams = null) {
    try {
      setSeccionesLoading(true);
      setPromotoresLoading(true);
      let params = {
        limite: -1,
        fields: "id,nombre",
        ordenar: "nombre.asc",
      };
      let merge = { ...params };
      if (extraParams) {
        merge = { ...merge, ...extraParams };
      }
      let resp = await Api.obtenerSecciones(merge);
      if (resp.resultado !== undefined && resp.resultado.length) {
        setSecciones(resp.resultado);
      }
    } catch (error) {
      alert(error);
    } finally {
      setSeccionesLoading(false);
      setPromotoresLoading(false);
    }
  }

  async function getPromotores(extraParams = null) {
    try {
      setPromotoresLoading(true);
      let params = {
        limite: -1,
        fields: "uid,nombre",
        ordenar: "nombre.asc",
      };
      let merge = { ...params };
      if (extraParams) {
        merge = { ...merge, ...extraParams };
      }
      let resp = await Api.obtenerUsuariosAuth(merge);
      if (resp.resultado !== undefined && resp.resultado.length) {
        setPromotores(resp.resultado);
      }
    } catch (error) {
      //alert(JSON.stringify(error));
      console.warn(error);
    } finally {
      setPromotoresLoading(false);
    }
  }

  const memData = useMemo(() => {
    return {
      distrito,
      seccion,
      promotor,
      distritos,
      secciones,
      promotores,
      distritosLoading,
      seccionesLoading,
      promotoresLoading,
      setDistrito,
      setSeccion,
      setPromotor,
      getSecciones,
      getPromotores,
    };
  }, [
    distrito,
    distritos,
    distritosLoading,
    seccion,
    secciones,
    seccionesLoading,
    promotor,
    promotores,
    promotoresLoading,
  ]);

  return <AppContext.Provider value={memData} {...props} />;
}

export function useApp() {
  const context = useContext(AppContext);

  if (!context) throw "error: app context not defined.";
  return context;
}
